$resize-factor: calc((100vw - 1366px) / 554);
$resize-factor2: calc((100vw - 768px) / 256);

@function resizeFormula($upperValue, $lowerValue, $rf) {
  @return calc(#{$rf} * (#{$upperValue} - #{$lowerValue}) + #{$lowerValue}px);
}
@mixin to1366($property, $upperValue, $lowerValue) {
  @media (min-width: 1920px) {
    #{$property}: #{$upperValue}px;
  }
  #{$property}: resizeFormula($upperValue, $lowerValue, $resize-factor);
}

@mixin to768($property, $upperValue, $lowerValue) {
  @media (max-width: 1100px) {
    #{$property}: resizeFormula($upperValue, $lowerValue, $resize-factor2);
  }
}


html {
  height: 100% !important;
  font-family: Lato !important;
  margin: 0 !important;
  letter-spacing: 0.05em !important;
  // font-size: 62.5%;
}

.page-wrapper {
  max-width: 1920px;
  margin: 0 auto;
  @media (min-width: 1930px) {
    box-shadow: 2px 0 5px -2px grey, -2px 0 5px -2px grey;
  }
}

.row {
  margin: 0;
}

p {
  margin: 0;
  padding: 0;
  text-decoration: none;
  :hover {
    text-decoration: none;
  }
}

body {
  margin: 0 !important;
  padding: 0 !important;
  height: 100%;
  background: white;
  font-family: Lato;
  // letter-spacing: 0.05em;
  // overflow: auto;
}

.shadow-layer {
  position: fixed;
  height: 16px;
  margin-top: -16px;
  width: 100%;
  z-index: 500;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.shadow-hider {
  background: white;
  height: 8px;
  z-index: 800;
}

.base-medium-font {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #11214b !important;
  letter-spacing: 0.05em !important;
}

.header-font {
  @extend .base-medium-font;
  @media (max-width: 1199px) {
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 30px;
  }
}

h1 {
  padding: 0;
  margin: 0;
  font-weight: bold;
  letter-spacing: 0.05em !important;
  font-size: 48px;
  line-height: 58px;

  @media (max-width: 1500px) {
    font-size: 40px;
    line-height: 48px;
  }
}

h2 {
  padding: 0;
  margin: 0;
  letter-spacing: 0.05em !important;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #11214b !important;

  @media (max-width: 1500px) {
    font-size: 24px;
    line-height: 29px;
  }
  @media (max-width: 500px) {
    font-size: 18px;
    line-height: 22px;
  }
}

h4 {
  padding: 0;
  margin: 0;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  color: #11214b !important;
  @media (max-width: 1500px) {
    font-size: 18px;
    line-height: 22px;
  }
  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 19px;
  }
}

.button {
  @extend .base-medium-font;
  // @extend .no
  text-decoration: none;
  cursor: pointer;
  background: #34b3f5;
  // min-width: 110px !important;
  // width: 160px;
  border-radius: 23px;
  display: inline-block;

  color: white !important;

  &:focus {
    outline: none !important;
  }
  border: none;
  &:hover {
    background: #68cbfe;
    color: white !important;
    border: none;
  }
  @include to1366(width, 208, 174);
  height: 50px;
  @media (max-width: 1366px) {
    height: 46px;
    width: 174px;
  }
}

.button--reverse {
  background-color: white;
  color: #34b3f5 !important;
  border: 2px solid #34b3f5;
}

.button--square {
  background: #34b3f5;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: le;
}

.fs-1 {
  font-weight: normal;
  font-size: 15px;
  line-height: 30px;
  letter-spacing: 0.05em !important;
  @media (max-width: 1500px) {
    font-size: 13px;
  }
  @media (max-width: 700px) {
    line-height: 22px;
  }
}

.fs-2 {
  font-weight: normal;
  font-size: 14px;
  line-height: 40px;
  color: white;
  letter-spacing: 0.05em !important;
}

.section-1 {
  &__image {
    @media (max-width: 1100px) {
      display: none;
      width: 100%;
    }
    min-width: 360px;
  }

  &__text {
    width: 760px;
    @include to1366(margin-top, 255, 168);
    @media (max-width: 1500px) {
      width: 635px;
    }
    @media (max-width: 800px) {
      width: 90%;
    }
  }
}

.desktop-size {
  @media (min-width: 1099px) {
    &--display {
      display: inherit !important;
    }
    &--no-display {
      display: none !important;
    }
  }
}

.tablet-size {
  @media (max-width: 1100px) {
    &--no-display {
      display: none !important;
    }
    &--display {
      display: inherit !important;
    }
    &--center-text {
      text-align: center !important;
    }
    &--flex-column {
      flex-direction: column;
    }
    &--align-itmes-center {
      align-items: center;
    }
  }
}

.mobile-size {
  @media (max-width: 500px) {
    &--no-display {
      display: none !important;
    }
    &--display {
      display: inherit !important;
    }
    &--center-text {
      text-align: center !important;
    }
    &--w-100 {
      width: 100% !important;
    }
    &--m-0 {
      margin: 0 !important;
    }
    &--mx-auto {
      margin-left: auto !important;
      margin-right: auto !important;
    }
    &--float-none {
      float: none !important;
    }
  }
}

.section-2 {
  &__content {
    width: 638px;
    @include to1366(margin-right, 325, 157);
    @include to1366(margin-top, 180, 84);
    margin-left: 90px;
    @media (max-width: 1500px) {
      width: 513px;
    }
    @media (max-width: 1100px) {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 30px;
    }
    @media (max-width: 500px) {
      margin-top: 12px;
    }
  }
  &__image {
    @include to1366(margin-left, -16, -37);
  }
  &__list {
    margin: 26px 0 34px;
    border-left: 1px solid #9fce06;
    padding-left: 40px;
    & > li {
      padding-bottom: 8px;
    }
    @media (max-width: 1100px) {
      border-left: none;
      padding-left: 0;
    }
  }
  &__circles-top {
    width: 45%;
  }
}

.section-benefits {
  scroll-margin-top: 120px;
  @include to1366(margin-top, 110, 80);
  @media (max-width: 1366px) {
    margin-top: 80px;
  }

  &__benefits {
    padding-top: 77px;
  }

  &__element {
    @include to1366(width, 327, 264);
    border: 1px solid #f4f4f4;
    border-radius: 5px;
    height: 390px;
    padding: 30px 15px 0;
    @media (max-width: 1366px) {
      width: 264px;
    }
    @media (max-width: 1200px) {
      width: auto;
      max-width: 327px;
      min-width: 264px;
      height: 293px;
    }
    @media (max-width: 330px) {
      min-width: unset;
    }
    &:hover {
      background: #f7f8fa;
      box-shadow: 2px 7px 7px rgba(0, 0, 0, 0.1);
      border: 1px solid #f7f8fa;
    }
  }
}

.testimonial {
  &__background {
    @include to1366(width, 1117, 872);
    @include to1366(left, 133, 24);
    top: 27px;
    // @include to1366( width, 792, 552);
    @media (max-width: 1366px) {
      // width: 872px;
      // left: 24px;
      // top: 93px;
    }
  }

  &__title {
    margin-top: 79px;
  }

  &__testimony {
    z-index: 10;
    background: #ffffff;
    box-shadow: 2px 6px 6px rgba(0, 0, 0, 0.3);
    border-radius: 25px;
    margin: auto;
    padding-top: 30px;
    padding-bottom: 22px;
    @include to1366(width, 461, 368);
    @include to1366(height, 451, 419);
    @include to1366(padding-left, 40, 32);
    @include to1366(padding-right, 35, 30);
    @media (max-width: 1366px) {
      width: 368px;
      height: 419px;
      padding-left: 32px;
      padding-right: 25px;
    }
    @media (max-width: 1200px) {
      width: 392px;
      height: 444px;
      @include to768(width, 392, 333);
      @include to768(height, 444, 450);
    }
    @media (max-width: 400px) {
      width: 90%;
    }

    &:hover {
      background: #fcfcfc;
      box-shadow: 2px 6px 6px rgba(0, 0, 0, 0.3);
    }
    &__image {
      @include to1366(height, 86, 67);
    }
    &__name {
      font-size: 22px;
      line-height: 30px;
      letter-spacing: 0.05em;
      font-weight: bold;
      color: #2D5287; 
      @media (max-width: 1500px) {
        font-size: 18px;
      }
      @media (max-width: 5500px) {
        font-size: 16px;
      }
    }
    &__quotation {
      height: 28px;
      // margin-top: 49px;
      margin-bottom: 17px;
      @media (max-width: 1366px) {
      }
    }
  }

  &__testimony-wrapper {
    padding: 10px 0;
  }
}

.sectionCheckmarks {
  margin-top: 46px;
  scroll-margin-top: 120px;
  @media (max-width: 500px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  &__image {
    @include to1366(width, 707, 547);
    @include to1366(height, 707, 547);
    @media (max-width: 600px) {
      display: none;
    }
  }

  &__list {
    padding-top: 33px;
    padding-left: 20px;
    margin-top: auto;
    margin-bottom: auto;
    @media (max-width: 1100px) {
      padding-left: 0;
    }
  }

  &__checkmark {
    height: 19px;
    padding-right: 21px;
    @media (max-width: 500px) {
      height: 16px;
      padding-right: 17px;
    }
  }
}

.frontPage--padding-left {
  // padding-left: $margin-left;
  @include to1366(padding-left, 206, 72);
  @include to768(padding-left, 88, 46);
  @media (max-width: 500px) {
    padding-left: 15px;
  }
}

.frontPage--padding-right {
  // padding-right: $margin-left;
  @include to1366(padding-right, 206, 72);
  @include to768(padding-right, 88, 46);
  @media (max-width: 500) {
    padding-right: 15px;
  }
}

.frontPage--margin-left {
  // margin-left: $margin-left;
  @include to1366(margin-left, 206, 72);
  @include to768(margin-left, 88, 46);
  @media (max-width: 500px) {
    margin-left: 15px;
  }
}

.frontPage--margin-right {
  // margin-right: $margin-left;
  @include to1366(margin-right, 206, 72);
  @include to768(margin-right, 88, 46);
  @media (max-width: 500px) {
    margin-right: 15px;
  }
}

.w-inherit {
  width: inherit;
}

.contactUs__image {
  @include to1366(width, 812, 593);
  // @media (max-width: 1366px) {
  //   width: 593px;
  // }
  @media (max-width: 1000px) {
    width: 60%;
  }
  @media (max-width: 499px) {
    width: 300px;
    height: 295px;
  }
}

.contactUs__rightSide {
  @include to1366(margin-left, 186, 39);
  @media (max-width: 1366px) {
    margin-left: 39px;
  }
  @media (max-width: 1000px) {
    margin: auto;
  }
  @media (max-width: 607px) {
    text-align: center;
  }
}

.contactableArea {
  @include to1366(width, 712, 607);
  @media (max-width: 1366px) {
    width: 607px;
  }
  @media (max-width: 1150px) {
    margin-left: auto !important;
    margin-right: auto !important;
    float: unset !important;
    clear: both;
  }
  @media (max-width: 607px) {
    width: 100%;
    margin: 0 !important;
    padding: 0 18px;
    // text-align: center;
  }
}

.contactUs__logo {
  @include to1366(margin-top, 107, 45);
  @media (max-width: 1366px) {
    margin-top: 45px;
  }
  @media (max-width: 499px) {
    display: none;
  }
}

.contactUs__title {
  margin-top: 22px;
  @media (max-width: 1366px) {
    margin-top: 11px;
  }
}

.fs-contact-title {
  font-weight: bold;
  color: #37539d;
  font-size: 40px;
  line-height: 54px;
  @media (max-width: 1500px) {
    font-size: 34px;
    line-height: 46px;
  }
}

.contactableArea__subText {
  @include to1366(margin-top, 20, 11);
  @media (max-width: 1366px) {
    margin-top: 11px;
  }
}

.contactableArea__form {
  margin: 26px 0;
}

.barLogo {
  padding: 0 100px;
  min-height: 42px;
  @media (max-width: 499px) {
    padding: 0 10px;
  }
}

.barLogo__bar {
  @include to1366(height, 91, 42);
  @media (max-width: 1366px) {
    height: 42px;
  }
}

.demo {
  &__image {
    @include to1366(width, 747, 562);
    // @media (max-width: 1366px) {
    //   width: 562px;
    // }
    @media (max-width: 499px) {
      width: 258px;
      height: 234px;
    }
  }
  &__contactableArea {
    @include to1366(margin-left, 179, 86);
  }
  &__title {
    @include to1366(margin-top, 53, 49);
    width: inherit;
    clear: right;
    @media (max-width: 1366px) {
      margin-top: 49px;
    }
    @media (max-width: 499px) {
      margin-top: 5px;
    }
  }
  &__arrow {
    @include to1366(margin-top, 35, 25);
    margin-bottom: 0;
    @media (max-width: 1150px) {
      margin-top: -100px;
      margin-bottom: 0;
    }
  }
  &__logo {
    @include to1366(margin-top, 44, 34);
    margin-left: 0px;
    @media (max-width: 1366px) {
      margin-top: 34px;
    }
    @media (max-width: 1150px) {
      margin-top: -150px;
      margin-bottom: 0;
    }
    @media (max-width: 607px) {
      float: left;
      margin-top: -100px;
    }
  }
}

.pricing__image {
  @include to1366(width, 792, 552);
  @media (max-width: 499px) {
    display: none;
  }
}

.pricing_image--mobile {
  display: none;
  @media (max-width: 499px) {
    display: block;
    width: 228px;
  }
}

.title--underliner {
  margin-top: 34px;
  width: 490px;
  @media (max-width: 1100px) {
    width: 237px;
  }
  @media (max-width: 500px) {
    width: 50px;
    float: none !important;
    // margin-left: auto;
    // margin-right: auto;
    margin-top: 17px;
  }
}

.grid {
  &--justify-self {
    &-center {
      justify-self: center;
    }
    &-end {
      justify-self: end;
    }
  }
}

.footer {
  color: white;
  margin-top: 33px;
  padding-top: 175px;
  background-image: url("../images/footer_background.png");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  &__content {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-areas: "logo aboutUs findUs";
    @media (max-width: 700px) {
      grid-template-columns: 100%;
      grid-template-areas:
        "logo"
        "findUs"
        "aboutUs";
      justify-items: center;
      text-align: center !important;
    }
  }
  &__logoarea {
    grid-area: logo;
  }
  &__aboutUsArea {
    grid-area: aboutUs;
    justify-self: end;
    @media (max-width: 700px) {
      justify-self: center;
      margin-top: 24px;
    }
  }
  &__findUsArea {
    grid-area: findUs;
    justify-self: center;
    @media (max-width: 700px) {
      &__title {
        display: none;
      }
      margin-top: 11px;
    }
  }
  &__title {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 8px;
  }

  @media (max-width: 1000px) {
    background-image: url("../images/footer_background_tablet.png");
  }
  @media (max-width: 500px) {
    background-image: url("../images/footer_background_mobile.png");
  }
}

.frontPage__barLogos {
  // grid-template-columns: repeat(6, 1fr);
  display: flex;
  justify-content: space-between;
  padding-bottom: 90px;
  border-bottom: 1px solid #d5d8e0;
  @media (max-width: 1100px) {
    margin-top: 88px;
  }
  @media (max-width: 600px) {
    display: grid;
    justify-items: center;
    column-gap: auto;
    margin-top: 40px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 20px 0px;
    gap: 20px 0px;
  }
}

.frontPage__barLogo {
  @include to1366(height, 65, 54);
  @media (max-width: 1366px) {
    height: 54px;
  }
  &--onlySmall {
    @media (min-width: 601px) {
      display: none;
    }
  }
  &--onlyBig {
    @media (max-width: 600px) {
      display: none;
    }
  }
  // @media (max-width: 499px) {
  //   display: none;
  // }
}

.word--blue {
  color: #37539d;
}

.navbar--custom {
  padding-top: 21px;
  padding-bottom: 21px;
  @media (max-width: 1200px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.navbar__elm {
  &--for-big {
    @media (max-width: 1199px) {
      display: none !important;
    }
  }
  &--for-small {
    @media (min-width: 1200px) {
      display: none !important;
    }
  }
  &--for-not-xs {
    @media (max-width: 700px) {
      display: none !important;
    }
  }
  &--for-xs {
    @media (min-width: 699px) {
      display: none !important;
    }
  }
}

.navbar-brand {
  margin-right: 0;
}

.contactUs__backArrow {
  position: absolute;
  width: 28px;
  @include to1366(left, 210, 87);
  @include to1366(top, 103, 77);
  @include to1366(height, 28, 25);
  @media (max-width: 1200px) {
    left: 5%;
  }
}

.col-4 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.navbar-toggler {
  padding: 0px;
  border: none;
}

.navbar-light .navbar-toggler-icon {
  height: 36px;
  width: 36px;
  @media (max-width: 500px) {
    height: 28px;
    width: 28px;
  }
  background-image: url("../images/hamburger.svg");
}

.navbar__small-logo {
  height: 40px;
  width: 44px;
  @media (max-width: 500px) {
    height: 31px;
    width: 35px;
  }
}