.default-input {
  border-radius: 5px;
  border: 1px solid #b4b4b4;
  padding: 10px;
  margin-bottom: calc(#{$resize-factor} * (18 - 10) + 10px);
  @media (max-width: 1366px) {
    margin-bottom: 10px;
  }
  @media (max-width: 499px) {
    margin-bottom: 8px;
  }
}

.custom-input {
  @extend .default-input;
  width: calc(#{$resize-factor} * (343 - 298) + 298px);
  height: calc(#{$resize-factor} * (50 - 46) + 46px);
  @media (max-width: 1366px) {
    width: 298px;
    height: 46px;
  }
  @media (max-width: 607px) {
    width: 100%;
    height: 44px;
  }
}

.custom_input--error {
  // margin-bottom: 20px;
  border-width: 1px 1px 1px 10px;
  border-style: solid;
  border-color: rgb(191, 22, 80) rgb(191, 22, 80) rgb(191, 22, 80) rgb(236, 89, 144);
  background: rgb(251, 236, 242);
}
