.divider {
  color: #11214B;

  &__chooseText {
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;

    @media (max-width: 800px) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  &__elements {
    padding: 0 2px;
  }
  
  &__element {
    margin: 0 100px;
    border-radius: 10px;
    padding: 60px;
    @media  (max-width: 1500px) {
      margin: 0 40px;
    }
    @media (max-width: 900px) {
      margin: 0 30px;
      padding: 30px;
    }
    @media (max-width: 800px) {
      margin: 0 5px;
      padding: 9px;
      box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.25);
      background: #FCFCFC;
    }
    &:hover {
      box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.25);
      background: #FCFCFC;
    }
    
    &__inner {
      max-width: 420px;
      height: 240px;    
    }
    
    &__image {
      max-width: 247px;
      margin-bottom: 40px;
    }
    &__title {
      font-size: 26px;
      line-height: 31px;
      @media (max-width: 800px) {
        font-size: 18px;
        line-height: 22px;
      }
    }
    &__text {
      font-size: 20px;
      line-height: 26px;
      font-weight: 500;
      @media (max-width: 800px) {
        font-size: 15px;
        line-height: 26px;
      }
    }
    &__button {
      margin-top: 10px;
      width: 90%;
      max-width: 315px;
    }
  }
}
